.header #logo:hover{
    transform-origin: center;
    /* cursor:pointer; */
    transform: scale(1.1) translateX(4%) translateY(0%);
    color: #3aac99;
}
/* 
.header #logo{
    /* width: 200px; */
    /* transition: all .2s ease-in-out; */
    /* font-size: 20pt; 
    font-size: clamp(0.9375rem, 0.4911rem + 1.9048vw, 1.5625rem);
} */

.header span{
    transition: all .2s ease-in-out;
}

.header img{
    width: 30px;
    margin-bottom: 5px;
} 

span.studiolink{
    color:#363636;
    font-family: "monSemi";
    margin-right: 15px;
}

span.studiolink:hover{
    color:#367833;

}

span.produktlink{
    color:#363636;
    font-family: "monSemi";
    margin-right: 15px;
}

span.produktlink:hover{
    color:#513378;
}

@font-face {
    font-family: "monc";
    src: url("../../assets/Montserrat-Bold.ttf");
}

.header #logo{
    /* width: 200px; */
    transition: all .2s ease-in-out;
    font-size: 20pt;
    font-family: "monc";
    
}

nav {
    text-align: right;
}

@media screen and (max-width: 600px) {
    .header{
        text-align: center;
    }
    
    .header #logo:hover{
      transform: scale(1.1) translateX(0%) translateY(0%);
    }

    nav {
        text-align: center;
    }
}

.header #logo:hover span.logo1{
    color: #9045a8;
}
.header #logo:hover span.logo2{
    color: #dc3a3a;
}
.header #logo:hover span.logo3{
    color: #25905d;
}
.header #logo:hover span.logo4{
    color: #30358b;
}
.header #logo:hover span.logo5{
    color: #ff9f80;
}
.header #logo:hover span.logo6{
    color: #8567ab;
}
.header #logo:hover span.logo7{
    color: #ce543c;
}
.header #logo:hover span.logo8{
    color: #47855a;
}

#kurvknap {
    background: linear-gradient(45deg, #fef3ec80, #d5bbd780) !important;
    color: #000 !important;
    border-radius: 10px !important;
    padding: 8px 10px !important;
    /* display: flex !important;*/
    margin-left: 10px !important;
    align-items: center !important;
    font-family: monFat !important;
}

#kurvknap:hover {
    background-color: #d5bbd7 !important;
}

#kurvknap .MuiSvgIcon-root{
    margin-left: 2px !important;
}
