@font-face {
    font-family: 'lars'; /*a name to be used later*/
    src: url('./lars.ttf'); /*URL to font*/
}

@font-face {
    font-family: 'print'; /*a name to be used later*/
    src: url('./johnny.ttf'); /*URL to font*/
}

@font-face {
    font-family: "monFat";
    src: url('./Montserrat-Bold.ttf');
}

@font-face {
    font-family: "monThin";
    src: url('./Montserrat-Thin.ttf');
}

@font-face {
    font-family: "monReg";
    src: url('./Montserrat-Regular.ttf');
}

@font-face {
    font-family: "monSemi";
    src: url('./Montserrat-SemiBold.ttf');
}

body {
    /*background-color: #fff;*/
    background-color: #F1F3F7;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23fbffea'/%3E%3Cstop offset='1' stop-color='%23fbffea' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23abe4e2'/%3E%3Cstop offset='1' stop-color='%23abe4e2' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23d3a6e2'/%3E%3Cstop offset='1' stop-color='%23d3a6e2' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23F1FFF7'/%3E%3Cstop offset='1' stop-color='%23F1FFF7' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23FFE3E3'/%3E%3Cstop offset='1' stop-color='%23FFE3E3' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%2394979A'/%3E%3Cstop offset='1' stop-color='%2394979A' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
    font-family: 'monReg' !important; 
    /* font-weight:200; */
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004) !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    text-rendering: optimizeLegibility !important;
    /* height: 100%; */
}


.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

footer{
    margin-top: auto;
}

a:hover{
    text-decoration: inherit !important;
    color: inherit !important;
}
a{
    text-decoration: inherit !important;
    color: inherit !important;
}

h1 {
    font-family: "monSemi";
}

.connect-button {
    margin-top: 10px;
    padding: 5px;
    width: 190px;
    text-align: center;
    background-color: #ec6316;
    color: #fff;
    border-radius: 5pt;
}

.connect-button:hover {
    background-color: #ad470f;
}

.shad{
    padding: 15px;
    border-radius: 15px;
    transition: all .2s ease-in-out;
    background-color: rgba(255,255,255,0.4);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.shad:hover{
    cursor: pointer;
    transform: scale(1.05);
}

.shad-no-hover{
    font-family: "monReg";
    padding: 20px;
    border-radius: 15px;
    background-color: rgba(255,255,255,0.4);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

#video{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    width: 100%;
}
/* footer{
    position: relative;
    left: 0;
    bottom: 0;
    right: 0;
}

#root{
    padding-bottom:60px;
} */

#theUpdateButton {
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
    background: linear-gradient(45deg, #aaacb1, #a6d5a2) !important;
    color: #000 !important;
    font-family: monReg !important;
}

#theUpdateButton:hover {
    background: linear-gradient(45deg, #898a8e, #e4c4d8) !important;
}

#theDeleteButton {
    color: #000 !important;
    font-family: monReg !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
    background-color: #00000020 !important;
}

#theDeleteButton:hover {
    background-color: grey !important;
}

#theDeleteDialog {
    font-family: monReg !important;
}
