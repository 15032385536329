span#stepperHead .MuiStepLabel-label{
    font-family: monReg !important;
}

#betalKnap {
    background: linear-gradient(to right, #80b187, #33a02c);
    text-transform: none;
    border-radius: 0px;
    height: 40px;
    width: 100px;
    margin-top: 10px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}

#betalKnap .MuiButtonLabel {
    background-color: transparent;
}

#betalKnap:hover {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.7);
}

#betalKnap button {
    font-family: monFat;
    color: white
}
