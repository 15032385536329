form#formCheck {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: monReg !important;
}

form#formCheck .MuiFormControl-root {
    width: 100% !important;
}

form#formCheck .MuiOutlinedInput-root {
    font-family: monReg !important;
    font-size: 1em;
}

form#formCheck #textField {
    width: 100% !important;
    font-family: monReg !important;
}

form#formCheck #textField .MuiOutlinedInput-root {
    font-family: monReg !important;
}

form#formCheck #textField .MuiInputLabel-root{
    font-family: monReg !important;
}

form#formCheck #textField .MuiInputBase-input{
    font-family: monReg !important;
}

form#formCheck #textField-label{
    font-family: monReg !important;

}

form#formCheck #confirmKnap {
    background: linear-gradient(45deg, #80b187, #33a02c);
    text-transform: none;
    border-radius: 0px;
    height: 40px;
    width: 100px;
    margin-top: 10px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}

form#formCheck span{
    font-family: monReg !important;
    /* font-size: .9em; */
}

form#formCheck #confirmKnap:hover {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.7);
}

form#formCheck #confirmKnap .MuiButtonLabel {
    background-color: transparent;
}

form#formCheck #confirmKnap button {
    font-family: monFat;
    color: white;
}

form#formCheck #termsLink {
    color: #0000ff60 !important;
}

