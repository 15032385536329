#footerRoot {
    margin-top: 60px !important;
    margin-bottom: 30px !important;
    background: unset !important;
}
#footerRoot:hover {
    background: unset !important;
}

@media screen and (max-width: 900px){
    #footerRoot {
        justify-content: center;
    }
    #footerNav {
        width: 100%;
        margin-left: auto;
        margin-bottom: 10px;
    }
    #footerLink {
        margin-bottom: 10px;
    }
    #footerMLink {
        margin-bottom: 15px;
    }
}

#footerNav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

#footerLink {
    font-size: .7em;
    font-family: monReg;
    margin-left: 15px;
    margin-right: 15px;
}
