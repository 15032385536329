#shoppingCartModal .MuiBox-root {
    max-height: 90%;
    overflow-y: auto;
    overflow-x: hidden; /* hide horizontal scrollbar */
}



#shoppingCartModal .MuiBox-root {
    /* max-height: 300px; adjust as needed */
    overflow-y: auto;
    scrollbar-width: none; /*hide scrollbar if not needed*/
    border-radius: 3px;
    border:rgba(0, 0, 0, 0.1)
  }
  
  
  /* Show scrollbar for Firefox */
  #shoppingCartModal .MuiBox-root::-moz-scrollbar {
    width: 5px;
  }
  
  #shoppingCartModal .MuiBox-root::-moz-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 10px;
  }
  
  #shoppingCartModal .MuiBox-root::-moz-scrollbar-track {
    background-color: #eee;
    border-radius: 10px;
  }

  #shoppingCartModal .MuiBox-root::-webkit-scrollbar {
    width: 8px; /* set the width of the scrollbar */
  }
  
  #shoppingCartModal .MuiBox-root::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 8px;
    border: 1px solid #ccc;
  }
  
  #shoppingCartModal .MuiBox-root::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
  }
  
  #shoppingCartModal .MuiBox-root::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 8px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  
  #shoppingCartModal .MuiBox-root::-webkit-scrollbar-track:hover {
    background-color: #eaeaea;
  }



@media screen and (max-width: 600px) {
    #shoppingCartModal .MuiBox-root{
        width: 98%;
    }
}

