@font-face {
  font-family: 'lars'; /*a name to be used later*/
  src: url('../../assets/lars.ttf'); /*URL to font*/
}

@font-face {
  font-family: 'print'; /*a name to be used later*/
  src: url('../../assets/johnny.ttf'); /*URL to font*/
}

@font-face {
  font-family: 'montsFat';
  src: url('../../assets/Montserrat-Bold.ttf');
}


.poster {
    width: 500px;
    height: 700px;
    background-color: white;
    padding: 0px;
    transform-origin: top left;
    /* visibility: hidden; */
    /* float: right; */
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}

/* .poster {
  position: relative;
} */

.poster::after {
  content: "Print-lap.dk";
  font-size: 2rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 300px;
  /* left: 0; */
  right: 32%;
  /* bottom: 15px; */
  transform: rotate(42deg);
  transform-origin: center left;
  text-align: center;
  height: 20%;
}

.poster::before {
  content: "Ingen vandmærke ved køb";
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 10px;
  right: 10px;
}


.poster svg {
    /* width: 30px; */
    /* width: 100%; */
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 600px){
  .poster{
    /* float:none; */
    /* transform: scale(0.784) !important; */
  }
}

@media screen and (max-width: 500px) {
  .poster {
    /* width: 98vw; */
  }
  div#poster.poster div.d-flex.flex-column.container div.flex-grow-1.row div.col svg{
    /* transform: scale(0.98) !important; */
    /* transform-origin: top left; */
    /* transform-box: fill-box; */
  }
}

span.legendText{
  font-family: 'monFat';
  font-size: 10px;
}


input[type=number] {
  appearance: none;
  background-color: #f5f5f5;
  border: none;
  border-radius: 5px;
  color: #333;
  /* font-size: 16px; */
  padding: 3px;
  /* width: 200px; */
}
input[type=number]:focus {
  outline: none;
  box-shadow: 0 0 3px #2196F3;
}

div.list__card img {
  width: 10px;
}

div.list__card {
 

    padding-left: 10px;
    padding-right: 10px;

    font-size: 12px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
    width: 250px;
    text-align: left;
    text-decoration: none;
    white-space: nowrap;
    
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    appearance: none;
    /* background-color: #FCFCFD; */
    border-radius: 4px;
    border-width: 0;
    margin-bottom: 10px;
    /* box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset; */
    box-sizing: border-box;
    /* color: #36395A; */
    cursor: pointer;
    display: inline-flex;
    font-family: "montsFat", "print", "JetBrains Mono",monospace;
    height: 32px;

    /* line-height: 1; */
    list-style: none;
  }

  div.list__card:focus {
    box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  }
  
  div.list__card:hover {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    /* transform: translateY(-2px); */
  }
  


  div.list__card:active {
    box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(2px);
  }


#buyKnap {
  background: linear-gradient(45deg, #80b187, #33a02c);
  font-family: monFat;
  color: white;
  text-transform: none;
  border-radius: 0px;
  height: 40px;
  width: 100%;
  margin-top: 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}

#buyKnap .MuiButtonLabel {
  background-color: transparent;
}

#buyKnap:hover {
  background: linear-gradient(48deg, rgb(125, 173, 153), rgb(106, 106, 168), rgb(189, 110, 110))
}

#configKnap {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 10px !important;
  display: block !important;
  background: linear-gradient(45deg, #aaacb1, #acc4d1) !important;
  color: #000 !important;
  font-family: monReg !important;
}

#configKnap:hover{
  background: linear-gradient(45deg, #898a8e, #e4c4d8) !important;
}
