#accordionHoved {
    border-radius: 10px !important;
    font-size: 1em !important;
    font-family: monSemi;
    background: linear-gradient(45deg, #f0f3f9, #e5dae2) !important;
    margin-bottom: 10px !important;
}

#accordionDet {
    font-size: 0.8em !important;
    font-family: monReg;
    line-height: 2;
    padding: 0 30px 30px 30px !important;
}
